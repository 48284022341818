import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>
        <strong>Политика конфиденциальности:</strong> Мы уважаем вашу конфиденциальность и гарантируем защиту ваших личных данных. Подробнее можно узнать, связавшись с нами.
      </p>
    </footer>
  );
}

export default Footer;
