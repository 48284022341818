import React from 'react';
import '../styles/ContactForm.css';
import { FaWhatsapp } from 'react-icons/fa'; // Пакет react-icons для иконки WhatsApp

function ContactForm() {
  return (
    <section id="form" className="contact-section">
      <div className="contact-info">
        <h2>Свяжитесь с нами</h2>
        <p>Остались вопросы? Мы с радостью ответим!</p>

        <div className="contact-links">
          <a href="tel:+79169871646" className="contact-link">
            +7(916)987-16-46
          </a>
          <a href="https://wa.me/79169871646" className="contact-link whatsapp-icon">
            <FaWhatsapp size={32} /> {/* Иконка WhatsApp */}
          </a>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
